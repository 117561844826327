import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Spinner,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateTime } from '../../../assets/data/Data';
import ModalWrapper from '../../../components/Modal/index';
import { GET, POST, PUT } from '../../../utilities/ApiProvider';

function AddDeliveryTime({
  data,
  onClose,
  fetchDeliveryData,
  deliveryId,
  selectedTime,
}) {
  console.log('Selected Items', selectedTime);
  const toast = useToast();

  function convertTo24Hour(timeStr) {
    console.log({ timeStr });
    if (selectedTime === null) {
      return null;
    }
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');

    // Normalize hours to a string
    hours = parseInt(hours, 10);

    // Convert to 24-hour format
    if (modifier.toUpperCase() === 'PM' && hours < 12) {
      hours += 12;
    }
    if (modifier.toUpperCase() === 'AM' && hours === 12) {
      hours = 0;
    }

    // Ensure hours are properly formatted
    const formattedTime = `${hours
      .toString()
      .padStart(2, '0')}:${minutes.padStart(2, '0')}`;
    console.log(formattedTime, 'completed time');
    return formattedTime; // Return in 24-hour format for <input type="time">
  }
  function convertTo12Hour(timeStr) {
    console.log({ timeStr });
    if (timeStr === null) {
      return null;
    }

    let [hours, minutes] = timeStr.split(':');
    hours = parseInt(hours, 10);

    // Determine the AM/PM modifier
    let modifier = 'AM';
    if (hours >= 12) {
      modifier = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    // Convert hours back to string for consistency
    hours = hours.toString();
    console.log({ hours, modifier });

    // Ensure hours and minutes are properly formatted
    const formattedTime = `${hours.padStart(2, '0')}:${minutes} `;
    console.log(formattedTime, 'completed time');
    return formattedTime;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      console.log(Array.from(formData.entries()));
      const datas = {
        storeId: '66792cc177d1c268a6bf34af',
        deliveryFee: [
          {
            day: data?.day,
            time: [
              {
                start: moment(formData.get('startTime'), 'HH:mm').format(
                  'HH:mm A'
                ),
                end: moment(formData.get('endTime'), 'HH:mm').format('HH:mm A'),
                fee: parseFloat(formData.get('deliveryFee')),
              },
            ],
            // faq: [
            //   {
            //     question: formData.get('question1'),
            //     answer: formData.get('answer1'),
            //   },
            //   {
            //     question: formData.get('question2'),
            //     answer: formData.get('answer2'),
            //   },
            // ],
          },
        ],
      };

      // Check if fields are filled
      if (
        formData.get('startTime') === '' ||
        formData.get('endTime') === '' 
       
      ) {
        toast({
          description: 'Please fill all required fields.',
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        return;
      }

      const payload = {
        day: data?.day,
        start: moment(formData.get('startTime'), 'HH:mm').format('HH:mm A'),
        end: moment(formData.get('endTime'), 'HH:mm').format('HH:mm A'),
        fee: parseFloat(formData.get('deliveryFee')),
      };

      let response;

      if (selectedTime) {
        response = await PUT(
          `/store/delivery/slot/${deliveryId}?timeSlotId=${selectedTime?._id}`,
          payload
        );
      } else {
        response = await POST(`/store/delivery/slot/${deliveryId}`, payload);
      }
      if (response.status === 'success') {
        toast({
          description: `${
            response.message || 'Delivery time added successfully'
          }`,
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
        fetchDeliveryData();
      } else {
        toast({
          description: `${response.message}`,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={2}>
        <FormControl>
          <FormLabel fontSize={'15px'}>Start Time</FormLabel>
          <Input
            py={6}
            name="startTime"
            type="time"
            placeholder="Enter Start Time"
            defaultValue={convertTo24Hour(selectedTime?.start) || ''}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>End Time</FormLabel>
          <Input
            py={6}
            name="endTime"
            type="time"
            placeholder="Enter End Time"
            defaultValue={convertTo24Hour(selectedTime?.end) || ''}
          />
        </FormControl>
        <FormControl display={'none'}>
          <FormLabel fontSize={'15px'}>Delivery Fee</FormLabel>
          <Input
            py={6}
            name="deliveryFee"
            type="text"
            placeholder="Enter Delivery Fee"
            defaultValue={selectedTime?.fee}
          />
        </FormControl>

        {/* <FormControl>
          <FormLabel fontSize={'15px'}>Question #01</FormLabel>
          <Input
            py={6}
            name="question1"
            type="text"
            placeholder="Enter End Time"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Answer</FormLabel>
          <Textarea
            resize={'none'}
            h={'100px'}
            name="answer1"
            py={6}
            type="text"
            placeholder="Enter Answer"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Question #02</FormLabel>
          <Input
            py={6}
            name="question2"
            type="text"
            placeholder="Enter End Time"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'15px'}>Answer</FormLabel>
          <Textarea
            resize={'none'}
            h={'100px'}
            py={6}
            type="text"
            name="answer2"
            placeholder="Enter Answer"
          />
        </FormControl> */}

        <Stack mt={'10px !important'}>
          <Button
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            type="submit"
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function Delivery() {
  const toast = useToast();
  const [datelist, setdatelist] = useState(dateTime);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [delivery, setDelivery] = useState(null);
  const [deliveryID, setDeliveryID] = useState('');
  const [day, setDay] = useState('');
  const [selectedSlot, setSelectedSlot] = useState(null);
  async function fetchDeliveryData() {
    try {
      const response = await GET(
        `/store/delivery/66792cc177d1c268a6bf34af?currentDate=${moment().format(
          'YYYY-MM-DD'
        )}`
      );
      console.log(response);
      if (response.status === 'success') {
        setDelivery(response.data[0]?.deliveryFee);
        setDeliveryID(response.data[0]?._id);
      } else {
        setDelivery([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function convertTo12Hour(timeStr) {
    console.log({ timeStr });
    if (timeStr === null) {
      return null;
    }

    let [hours, minutes] = timeStr.split(':');
    hours = parseInt(hours, 10);

    // Determine the AM/PM modifier
    let modifier = 'AM';
    if (hours >= 12) {
      modifier = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    // Convert hours back to string for consistency
    hours = hours.toString();
    console.log({ hours, modifier });

    // Ensure hours and minutes are properly formatted
    const formattedTime = `${hours.padStart(2, '0')}:${minutes} `;
    console.log(formattedTime, 'completed time');
    return formattedTime;
  }
  async function handleDelete(timeslotId, day) {
    try {
      const payload = {
        day: day,
      };
      const response = await PUT(
        `/store/delivery/slot/delete/${deliveryID}?timeSlotId=${timeslotId}`,
        payload
      );
      if (response.status === 'success') {
        toast({
          description: 'Delivery time deleted successfully',
          status: 'success',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
        fetchDeliveryData();
      } else {
        toast({
          description: `${response.message}`,
          status: 'error',
          duration: 5000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchDeliveryData();
  }, []);

  return (
    <Stack px={6} py={2}>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add New Delivery Time'}
        children={
          <AddDeliveryTime
            data={day}
            selectedTime={selectedSlot}
            deliveryId={deliveryID}
            onClose={onClose}
            fetchDeliveryData={fetchDeliveryData}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <Stack
        mb={2}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack display={'flex'} flexDirection={'column'}>
          <Heading fontSize={'30px'} fontWeight={'600'}>
            Delivery Fee
          </Heading>
        </Stack>

        <Button
          as={Link}
          to={'/dashboard/delivery-areas'}
          padding={'10px 30px'}
          bg={
            'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
          }
          color={'#fff'}
          fontWeight={'600'}
          lineHeight={'inherit'}
          fontSize={'15px'}
          borderRadius={6}
          _hover={{
            color: '#000',
          }}
          w={'fit-content'}
        >
          Delivery Areas
        </Button>
      </Stack>

      <Stack>
        {!delivery ? (
          <Stack h={'60vh'} alignItems={'center'} justifyContent={'center'}>
            <Spinner />
          </Stack>
        ) : (
          <Accordion allowToggle>
            {delivery?.map((item, index) => (
              <AccordionItem
                mb={3}
                border={'1px solid #B4B4B4'}
                background={'#F3F3F3'}
                borderRadius={'8px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  py={4}
                >
                  <Stack
                    w="100%"
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box px={4}>
                      <Heading fontSize={'22px'} fontWeight={'600'}>
                        {item.day}
                      </Heading>
                    </Box>
                    <Box px={4}>
                      <Button
                        bg={
                          'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                        }
                        w="max-content"
                        color={'#fff'}
                        fontWeight={'500'}
                        lineHeight={'inherit'}
                        borderRadius={12}
                        _hover={{
                          color: '#000',
                        }}
                        onClick={() => {
                          setDay(item);
                          onOpen();
                        }}
                      >
                        Add New
                      </Button>
                    </Box>
                  </Stack>

                  <AccordionButton width={'max-content'}>
                    <AccordionIcon />
                  </AccordionButton>
                </Box>
                <AccordionPanel>
                  <Stack gap={4}>
                    {item?.time?.map((time, index) => (
                      <>
                        <Stack
                          display={'flex'}
                          direction={'row'}
                          justifyContent={'space-between'}
                        >
                          <Box
                            width={'100%'}
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            borderRadius={8}
                            border={'1px solid #B4B4B4'}
                            height={'62px'}
                            px={6}
                          >
                            <Stack>
                              <Text
                                fontSize={{
                                  base: '15px',
                                  md: '14px',
                                  xl: '16px',
                                  '2xl': '18px',
                                }}
                                fontWeight={500}
                              >
                                Select Time:
                              </Text>
                            </Stack>
                            <HStack gap={1}>
                              <Button
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                                }
                                w="max-content"
                                color={'#fff'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                _hover={{
                                  color: '#000',
                                }}
                              >
                                {convertTo12Hour(time.start)}
                              </Button>
                              <Button
                                bg={
                                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
                                }
                                w="max-content"
                                color={'#fff'}
                                fontWeight={'500'}
                                lineHeight={'inherit'}
                                borderRadius={12}
                                _hover={{
                                  color: '#000',
                                }}
                              >
                                {convertTo12Hour(time.end)}
                              </Button>
                            </HStack>
                          </Box>
                          <HStack width={'20%'} justifyContent={'center'}>
                            <Button
                              w="50%"
                              h="34px"
                              fontSize={'13px'}
                              fontWeight={'600'}
                              border={'1px solid #1A1B1A'}
                              borderRadius={'8px'}
                              width={'max-content'}
                              onClick={() => {
                                setSelectedSlot(time);
                                setDay(item);
                                onOpen();
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              w="50%"
                              h="34px"
                              fontSize={'13px'}
                              fontWeight={'600'}
                              border={'1px solid #1A1B1A'}
                              borderRadius={'8px'}
                              width={'max-content'}
                              onClick={() => handleDelete(time._id, item?.day)}
                            >
                              Remove
                            </Button>
                          </HStack>
                        </Stack>
                        {/* <Stack>
                          <Box
                            borderRadius={8}
                            border={'1px solid #B4B4B4'}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            px={6}
                            height={'62px'}
                          >
                            <Text
                              fontSize={{
                                base: '15px',
                                md: '14px',
                                xl: '16px',
                                '2xl': '18px',
                              }}
                              fontWeight={500}
                            >
                              Delivery Fee:
                            </Text>
                            <Text
                              fontSize={'18px'}
                              fontWeight={600}
                              color={'#007341'}
                            >
                              Rs. {time?.fee || '100.00'}
                            </Text>
                          </Box>
                        </Stack> */}
                      </>
                    ))}
                  </Stack>
                  {/* <Text py={4} fontSize={'22px'} fontWeight={600}>
                    Question
                  </Text>
                  <Grid templateColumns={'repeat(2,1fr)'} gap={4}>
                    {item?.faq?.map((faq, index) => (
                      <Box display={'flex'} flexDirection={'column'} gap={3}>
                        <Text fontSize={'16px'} fontWeight={500}>
                          Question / Answer (1)
                        </Text>
                        <Box
                          fontSize={'18px'}
                          fontWeight={500}
                          display={'flex'}
                          alignItems={'center'}
                          borderRadius={8}
                          border={'1px solid #B4B4B4'}
                          height={'62px'}
                          px={6}
                        >
                          {faq.question ||
                            ' Can I cancel or amend a same day delivery?'}
                        </Box>
                        <Box
                          py={2}
                          fontSize={'18px'}
                          fontWeight={500}
                          borderRadius={8}
                          border={'1px solid #B4B4B4'}
                          height={'104px'}
                          px={6}
                        >
                          {faq?.answer ||
                            'Once you have placed your Same Day order you won’t be able to edit it'}
                        </Box>
                      </Box>
                    ))}
                  </Grid> */}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </Stack>
    </Stack>
  );
}

export default Delivery;
