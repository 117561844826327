import React from 'react';
import MainDashboard from '../../../components/DashNav/MainDashboard';
import { Stack } from '@chakra-ui/react';
import Categories from '../../../components/Dashboard/Product/Categories';
export default function Product() {
  return (
    <Stack overflowY="hidden">
      <MainDashboard>
        <Categories />
      </MainDashboard>
    </Stack>
  );
}
