import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Heading,
  Stack,
  Button,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  Text,
  TableContainer,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react';
import wishimg from '../../../assets/images/website/wishlist.jpg';
import { GET } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { convertDate } from '../../../utilities/helper';

export default function OrderHistory() {
  const [orderList, setorderList] = useState(null);
  const [orderdetail, setorderdetail] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const storeId = localStorage.getItem('storeId');

  useEffect(() => {
    if (storeId !== null) {
      getGroceries();
    }
    async function getGroceries() {
      try {
        const response = await GET(`/store/order?storeId=${storeId}`);
        console.log('orderList', response.data);
        if (response.status === 'success') {
          setorderList(response.data);
        } else {
          console.log('Error while fetching groceries');
        }
      } catch (error) {
        console.log(error, 'error while fetching groceries');
      }
    }
  }, []);

  function openModal(id) {
    onOpen();
    getOrderDetails(id);
  }

  async function getOrderDetails(id) {
    try {
      const response = await GET(`/store/order/${id}?storeId=${storeId}`);
      console.log('orderList details', response.data);
      if (response.status === 'success') {
        setorderdetail(response.data);
      } else {
        console.log('Error while fetching groceries');
      }
    } catch (error) {
      console.log(error, 'error while fetching groceries');
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Order Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TableContainer>
              <Table size="sm" variant="striped" colorScheme="green">
                <Tbody>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        name
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.name}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        Email
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.email}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        Phone
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.phone}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        Address
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.streetAddress}
                      </Text>
                    </Td>
                  </Tr>

                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        Order Status
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.orderStatus}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        Payment Method
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.paymentMethod}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        Purchased Date
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {new Date(orderdetail.createdAt).toLocaleDateString()}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        delivery Date
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {new Date(
                          orderdetail.deliveryDate
                        ).toLocaleDateString()}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        sub total
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.subtotal}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        tax
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.tax}
                      </Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td>
                      <Text textTransform={'capitalize'} fontWeight={600}>
                        total
                      </Text>
                    </Td>
                    <Td>
                      <Text textTransform={'capitalize'}>
                        {orderdetail.total}
                      </Text>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button
              color={'#fff'}
              bg={
                'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
              }
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack px={12} bg={'#fff'} py={12} borderRadius={12}>
            <Stack gap={8}>
              {orderList?.['orders']?.length === 0 && (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={'400px'}
                >
                  <Text fontSize={'20px'} fontWeight={600}>
                    No Orders Found
                  </Text>
                </Stack>
              )}
              {!orderList ? (
                <Stack
                  alignItems={'center'}
                  justifyContent={'center'}
                  height={'400px'}
                >
                  <Spinner />
                </Stack>
              ) : (
                Object.keys(orderList).map((item, ind) => {
                  if (orderList[item].length === 0) {
                    return;
                  }
                  return (
                    <Stack>
                      <Heading
                        mb={6}
                        color={'#111'}
                        fontSize={'20px'}
                        fontWeight={700}
                      >
                        {item == 'history' ? 'Order History' : 'My Orders'}
                      </Heading>
                      <TableContainer>
                        <Table variant="simple">
                          <Thead>
                            <Tr>
                              <Th>Order ID</Th>
                              <Th>Name</Th>
                              <Th>Purchased Date</Th>
                              <Th>Delivery Date</Th>
                              <Th>Order Status</Th>
                              <Th>Total Price</Th>
                              <Th>Action</Th>
                            </Tr>
                          </Thead>
                          <Tbody>
                            {orderList?.[
                              item == 'history' ? 'history' : 'orders'
                            ].map((v, k) => {
                              return (
                                <Tr key={k}>
                                  <Td>
                                    <Box
                                      alignItems={'center'}
                                      gap={2}
                                      display={'flex'}
                                      flexWrap={'wrap'}
                                    >
                                      {/* <Image  src={`${imgUrl}${v.picture}`}  /> */}
                                      <Text
                                        textTransform={'capitalize'}
                                        fontSize={'15px'}
                                      >
                                        {v?.invoice || 'KHI000123'}
                                      </Text>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Box
                                      alignItems={'center'}
                                      gap={2}
                                      display={'flex'}
                                      flexWrap={'wrap'}
                                    >
                                      {/* <Image  src={`${imgUrl}${v.picture}`}  /> */}
                                      <Text
                                        textTransform={'capitalize'}
                                        fontSize={'15px'}
                                      >
                                        {v.name}
                                      </Text>
                                    </Box>
                                  </Td>
                                  <Td>
                                    <Text fontSize={'15px'}>
                                      {/* {moment(v?.orderDate)?.format(
                                        'DD-MMM-YYYY'
                                      )} */}
                                      {convertDate(v?.orderDate)}
                                    </Text>
                                  </Td>
                                  <Td>
                                    <Text fontSize={'15px'}>
                                      {/* {moment(v?.deliveryDate)?.format(
                                        'DD-MMM-YYYY'
                                      )} */}
                                      {convertDate(v?.deliveryDate)}
                                    </Text>
                                  </Td>
                                  <Td textTransform={'capitalize'}>
                                    {v.orderStatus}
                                  </Td>
                                  <Td>
                                    <Text fontSize={'15px'}>{v.total}</Text>
                                  </Td>
                                  <Td>
                                    {v.orderStatus === 'cancelled' ? (
                                      <Button
                                        border={'2px solid red.100'}
                                        bg={'red.100'}
                                        color={'red.500'}
                                        padding={'10px 25px'}
                                        _hover={{
                                          border: '2px solid red.100',
                                          bg: 'red.100',
                                          color: 'red.500',
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    ) : (
                                      <Button
                                        border={'2px solid #07d57c'}
                                        padding={'10px 25px'}
                                        bg={'transparent'}
                                        onClick={() =>
                                          navigate(`/oderdetails/${v?._id}`)
                                        }
                                      >
                                        Order Detail
                                      </Button>
                                    )}
                                  </Td>
                                </Tr>
                              );
                            })}
                          </Tbody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  );
                })
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
