import { AddIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Heading,
  HStack,
  Icon,
  IconButton,
  Image,
  Input,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  Textarea,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';

import { IoArrowBack } from 'react-icons/io5';
import { RxCross2 } from 'react-icons/rx';
import { useNavigate, useParams } from 'react-router-dom';
import { DELETE, GET, POST, PUT } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import ModalWrapper from '../../Modal';

function AddSubCategory({ id, fetchCategories, onClose }) {
  const [file1, setFile] = useState(null);
  const toast = useToast();
  const handleFileChange1 = event => {
    console.log(event.target.files);
    setFile(event.target.files[0]);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      if (
        Array.from(formData.entries())?.some(([key, value]) => value === '')
      ) {
        toast({
          title: 'Please fill all the fields',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        return;
      }
      formData.append('image', file1);
      formData.append('parent', id);

      const response = await POST('/admin/category', formData);
      if (response.status === 'success') {
        console.log(response.data);
        onClose();
        fetchCategories();
        toast({
          title: 'Subcategory added successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Subcategory Name
          </FormLabel>
          <Input
            background={'#F3F3F3'}
            border={'1px solid #B4B4B4'}
            py={6}
            name="name"
            type="text"
            placeholder="Enter Your Name"
          />
        </FormControl>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Subcategory Picture
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('uploads').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload Picture
                </Text>
              </Box>
              <Input
                onChange={handleFileChange1}
                type="file"
                accept="image/*"
                display="none"
                id="uploads"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file1 && <Text marginTop="10px">{file1.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function EditSubCategory({ id, data, onClose, fetchCategories }) {
  const [subcategoryName, setSubcategoryName] = useState(data?.name || '');
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(data?.image || null);
  const toast = useToast();

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleCancelImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('image', file);
      const response = await PUT(`/admin/category/${id}`, formData);
      if (response.status === 'success') {
        toast({
          description: 'Sub Category updated successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
        fetchCategories();
      } else {
        toast({
          description: response?.message,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data?.picture) {
      setImagePreview(`${imgUrl}${data?.picture}`);
    }
  }, [data]);

  return (
    <Stack display={'flex'} flexDirection={'column'} gap={3}>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Subcategory Name
          </FormLabel>
          <Input
            background={'#F3F3F3'}
            border={'1px solid #B4B4B4'}
            py={6}
            name="name"
            value={subcategoryName}
            onChange={e => setSubcategoryName(e.target.value)}
            type="text"
            placeholder="Enter Your Name"
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Subcategory Picture
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position="relative"
          >
            {imagePreview ? (
              <Image
                src={imagePreview}
                alt="Subcategory Picture"
                boxSize="56px"
                objectFit="cover"
                borderRadius="8px"
              />
            ) : (
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={3}
              >
                <Box
                  w="24px"
                  h="24px"
                  borderRadius={'50%'}
                  border={'1px solid #1A1A1A'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color="#1A1A1A"
                >
                  <AddIcon fontSize={'12px'} />
                </Box>
                <Box marginTop={'0px !important'}>
                  <Text fontSize={'18px'} fontWeight={'500'}>
                    Upload Picture
                  </Text>
                </Box>
              </Stack>
            )}
            <Input
              type="file"
              position="absolute"
              top="0"
              accept="image/*"
              left="0"
              width="100%"
              height="100%"
              opacity="0"
              cursor="pointer"
              onChange={handleFileChange}
            />
          </Box>
          {/* {imagePreview && (
            <Button
              mt={2}
              onClick={handleCancelImage}
              colorScheme="red"
              size="sm"
            >
              Cancel Image
            </Button>
          )} */}
        </FormControl>

        <Stack py={3}>
          <Button
            type="submit"
            padding={'10px 50px'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Continue
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

function EditCategory({ id, data, onClose, fetchCategories }) {
  const [subcategoryName, setSubcategoryName] = useState(data?.name || '');
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(data?.image || null);
  const toast = useToast();

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setImagePreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleCancelImage = () => {
    setFile(null);
    setImagePreview(null);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('image', file);
      const response = await PUT(`/admin/category/${id}`, formData);
      if (response.status === 'success') {
        toast({
          description: 'Category updated successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
        fetchCategories();
      } else {
        toast({
          description: response?.message,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (data?.picture) {
      setImagePreview(`${imgUrl}${data?.picture}`);
    }
  }, [data]);

  return (
    <Stack display={'flex'} flexDirection={'column'} gap={3}>
      <form onSubmit={handleSubmit}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Category Name
          </FormLabel>
          <Input
            background={'#F3F3F3'}
            border={'1px solid #B4B4B4'}
            py={6}
            name="name"
            value={subcategoryName}
            onChange={e => setSubcategoryName(e.target.value)}
            type="text"
            placeholder="Enter Your Name"
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Category Picture
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            position="relative"
          >
            {imagePreview ? (
              <Image
                src={imagePreview}
                alt="Subcategory Picture"
                boxSize="56px"
                objectFit="cover"
                borderRadius="8px"
              />
            ) : (
              <Stack
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={3}
              >
                <Box
                  w="24px"
                  h="24px"
                  borderRadius={'50%'}
                  border={'1px solid #1A1A1A'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  color="#1A1A1A"
                >
                  <AddIcon fontSize={'12px'} />
                </Box>
                <Box marginTop={'0px !important'}>
                  <Text fontSize={'18px'} fontWeight={'500'}>
                    Upload Picture
                  </Text>
                </Box>
              </Stack>
            )}
            <Input
              type="file"
              position="absolute"
              top="0"
              accept="image/*"
              left="0"
              width="100%"
              height="100%"
              opacity="0"
              cursor="pointer"
              onChange={handleFileChange}
            />
          </Box>
          {/* {imagePreview && (
            <Button
              mt={2}
              onClick={handleCancelImage}
              colorScheme="red"
              size="sm"
            >
              Cancel Image
            </Button>
          )} */}
        </FormControl>

        <Stack py={3}>
          <Button
            type="submit"
            padding={'10px 50px'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
          >
            Continue
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}
export default function Categories() {
  const [categories, setCategories] = useState(null);

  const disclosure = useDisclosure();
  const [editId, setEditId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [categorySearch, setCategorySearch] = useState('');
  const [editSubCategory, setEditSubCategory] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const disclosure1 = useDisclosure();
  const disclosure2 = useDisclosure();
  const disclosure3 = useDisclosure();
  const [id, setId] = useState(null);
  const { onClose, isOpen, onOpen } = disclosure;
  const { onClose: onClose1, isOpen: isOpen1, onOpen: onOpen1 } = disclosure1;
  const { onClose: onClose2, isOpen: isOpen2, onOpen: onOpen2 } = disclosure2;
  const { onClose: onClose3, isOpen: isOpen3, onOpen: onOpen3 } = disclosure3;

  async function fetchCategories() {
    try {
      const response = await GET(
        `/admin/groceries?storeId=${localStorage.getItem('storeId')}`
      );
      if (response.status === 'success') {
        console.log(response.data);
        setCategories(response.data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddSubcategory = async id => {
    onOpen1();
    setId(id);
  };
  const handleRemoveCategory = async id => {
    try {
      const response = await DELETE(`/admin/category/${id}`);
      if (response.status === 'success') {
        console.log(response.data);
        setCategories(prevCategories =>
          prevCategories.filter(item => item._id !== id)
        );
        toast({
          title: 'Category removed successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditCategory = async item => {
    onOpen3();
    setEditId(item._id);
    setEditSubCategory(item);
  };
  const [file, setFile] = useState(null);
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      if (
        Array.from(formData.entries())?.some(([key, value]) => value === '')
      ) {
        toast({
          title: 'Please fill all the fields',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        return;
      }
      formData.append('image', file);

      const response = await POST('/admin/category', formData);
      if (response.status === 'success') {
        console.log(response.data);
        onClose();
        fetchCategories();
        e.target.reset();
        setFile(null);
        toast({
          title: 'Category added successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleRemoveSubCategory = async id => {
    try {
      const response = await DELETE(`/admin/category/${id}`);
      if (response.status === 'success') {
        fetchCategories();
        toast({
          title: 'Subcategory removed successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Stack gap={6} px={4} pb={6}>
      <ModalWrapper
        isOpen={isOpen1}
        onClose={onClose1}
        title={'Add New Subcategory'}
        children={
          <AddSubCategory
            id={id}
            fetchCategories={fetchCategories}
            onClose={onClose1}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <ModalWrapper
        isOpen={isOpen2}
        onClose={onClose2}
        title={'Edit Sub Category'}
        children={
          <EditSubCategory
            id={editId}
            data={editSubCategory}
            onClose={onClose2}
            fetchCategories={fetchCategories}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <ModalWrapper
        isOpen={isOpen3}
        onClose={onClose3}
        title={'Edit Category'}
        children={
          <EditCategory
            id={editId}
            data={editSubCategory}
            onClose={onClose3}
            fetchCategories={fetchCategories}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <form id="formSubmit" onSubmit={handleSubmit}>
        <SimpleGrid columns={2} spacing={16}>
          <Stack gap={4}>
            <Stack onClick={() => navigate(-1)} cursor={'pointer'}>
              <Icon as={IoArrowBack} w={10} h={10} />
            </Stack>
            <Stack>
              <Heading fontSize={'30px'} fontWeight={'700'}>
                Add New Category
              </Heading>
              {/* <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
                Here is the all user details...Check Now!
              </Text> */}
            </Stack>
            <Stack gap={4}>
              <FormControl>
                <FormLabel fontSize={'15px'}>Category Name</FormLabel>
                <Input
                  py={6}
                  name="name"
                  textTransform={'capitalize'}
                  type="text"
                  placeholder="Enter Category Name"
                />
              </FormControl>
              <FormControl>
                <FormLabel fontSize={'15px'}>Slug</FormLabel>
                <Input
                  py={6}
                  name="slug"
                  type="text"
                  placeholder="Enter Slug"
                />
              </FormControl>

              <FormControl>
                <FormLabel
                  fontSize={'16px'}
                  fontWeight={'500'}
                  lineHeight={'30px'}
                >
                  Category Picture
                </FormLabel>
                <Box
                  h="56px"
                  borderRadius={'8px'}
                  border={'1px dashed #B4B4B4'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  cursor={'pointer'}
                  onClick={() => document.getElementById('upload').click()}
                >
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                    gap={3}
                  >
                    <Box
                      w="24px"
                      h="24px"
                      borderRadius={'50%'}
                      border={'1px solid #1A1A1A'}
                      display={'flex'}
                      justifyContent={'center'}
                      alignItems={'center'}
                      color="#1A1A1A"
                    >
                      <AddIcon fontSize={'12px'} />
                    </Box>
                    <Box marginTop={'0px !important'}>
                      <Text fontSize={'18px'} fontWeight={'500'}>
                        Upload Picture
                      </Text>
                    </Box>
                    <Input
                      onChange={handleFileChange}
                      accept="image/*"
                      type="file"
                      display="none"
                      id="upload"
                    />
                    <label htmlFor="upload">
                      <Box
                        as="span"
                        display={'none'}
                        cursor="pointer"
                        padding="10px 20px"
                        borderRadius="8px"
                        border="1px solid #B4B4B4"
                        background="#F3F3F3"
                        _hover={{
                          background: '#E2E2E2',
                        }}
                      >
                        Choose File
                      </Box>
                    </label>
                    {file && <Text marginTop="10px">{file.name}</Text>}
                  </Stack>
                </Box>
              </FormControl>
              <Button
                type="submit"
                isLoading={loading}
                bg={
                  'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                }
                color={'#000'}
                width={'max-content'}
              >
                Save Changes
              </Button>
            </Stack>
          </Stack>
          <Stack>
            <Stack
              mb={2}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
            >
              <Stack display={'flex'} flexDirection={'column'}>
                <Heading fontSize={'40px'} fontWeight={'600'}>
                  Categories
                </Heading>
                <Text fontSize={'15px'} color={'#75767A'} fontWeight={'400'}>
                  Choose at least ({categories?.length || 0}) category:
                </Text>
              </Stack>
            </Stack>
            <Stack>
              <Input
                value={categorySearch}
                onChange={e => setCategorySearch(e.target.value)}
                placeholder="Search categories..."
                border={'1px solid #B4B4B4'}
                bg={'#F3F3F3'}
                borderRadius={'8px'}
              />
            </Stack>
            <Stack
              h={'calc(100vh - 280px)'}
              overflowY={'scroll'}
              margin={'0 auto'}
              gap={3}
            >
              {!categories ? (
                <Stack
                  w={'100%'}
                  h={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Spinner />
                </Stack>
              ) : (
                <Accordion allowToggle>
                  {categories
                    ?.filter(
                      item =>
                        // Filter by item name
                        item?.name
                          ?.toLowerCase()
                          .includes(categorySearch.toLowerCase()) ||
                        item?.children?.some(child =>
                          child?.name
                            ?.toLowerCase()
                            .includes(categorySearch.toLowerCase())
                        )
                    )
                    // categories
                    //   ?.filter(item =>
                    //     item?.name
                    //       ?.toLowerCase()
                    //       .includes(categorySearch.toLowerCase())
                    //   )
                    ?.map((v, index) => (
                      <AccordionItem
                        mb={3}
                        border={'1px solid #B4B4B4'}
                        background={'#F3F3F3'}
                        borderRadius={'8px'}
                      >
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          h={'62px'}
                          borderBottom={'1px solid #B4B4B4'}
                          py={4}
                        >
                          <Box
                            px={4}
                            display={'flex'}
                            alignItems={'center'}
                            gap={3}
                          >
                            {/* <Checkbox
                              colorScheme={'green'}
                              fontSize={'18px'}
                              fontWeight={'500'}
                              isChecked={
                                productId
                                  ? editProduct?.categories?.some(
                                      product => product._id === v?._id
                                    )
                                  : editProduct
                                  ? editProduct?.categories?.some(
                                      product => product === v?._id
                                    )
                                  : subCategories?.some(
                                      product => product === v?._id
                                    )
                              }
                              onChange={e => handleCheckboxChange(e, v?._id)}
                            ></Checkbox> */}
                            <Heading fontSize={'20px'} fontWeight={'600'}>
                              {v?.name}
                            </Heading>
                          </Box>

                          <Box
                            display={'flex'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            gap={6}
                          >
                            <HStack>
                              <Button
                                // onClick={onOpen2}
                                fontSize={'13px'}
                                fontWeight={'600'}
                                border={'1px solid #1A1B1A'}
                                borderRadius={'8px'}
                                width={'max-content'}
                                onClick={() => handleEditCategory(v)}
                              >
                                Edit
                              </Button>
                              <Button
                                fontSize={'13px'}
                                fontWeight={'600'}
                                border={'1px solid #1A1B1A'}
                                borderRadius={'8px'}
                                width={'max-content'}
                                onClick={() => handleRemoveCategory(v?._id)}
                              >
                                Remove
                              </Button>
                            </HStack>
                            <AccordionButton width={'max-content'}>
                              <AccordionIcon />
                            </AccordionButton>
                          </Box>
                        </Box>
                        <AccordionPanel
                          display={'flex'}
                          flexDirection={'column'}
                          gap={4}
                        >
                          <Stack
                            display={'flex'}
                            flexDirection={'row'}
                            // justifyContent={'center'}
                            alignItems={'center'}
                            gap={3}
                            flexWrap={'wrap'}
                          >
                            {v?.children?.length > 0 ? (
                              v?.children?.map((item, index) => (
                                <Box
                                  w="48%"
                                  display={'flex'}
                                  flexDirection={'row'}
                                  alignItems={'center'}
                                  gap={2}
                                >
                                  <Stack>
                                    <Text>{item?.name}</Text>
                                  </Stack>

                                  <Stack
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                    gap={2}
                                  >
                                    <Icon
                                      mt={'0 !important'}
                                      color={'green.300'}
                                      as={FaRegEdit}
                                      fontSize={'16px'}
                                      cursor={'pointer'}
                                      onClick={() => {
                                        setEditSubCategory(item);
                                        setEditId(item._id);
                                        onOpen2();
                                      }}
                                    />
                                    <Icon
                                      fontSize={'20px'}
                                      color={'red.300'}
                                      mt={'0 !important'}
                                      as={MdDeleteForever}
                                      onClick={() =>
                                        handleRemoveSubCategory(item._id)
                                      }
                                      cursor={'pointer'}
                                    />
                                  </Stack>
                                </Box>
                              ))
                            ) : (
                              <Text
                                fontSize={'15px'}
                                color={'#1A1B1A'}
                                fontWeight={'400'}
                                textAlign={'center'}
                              >
                                No Subcategories Found
                              </Text>
                            )}
                          </Stack>

                          <Button
                            w="100%"
                            onClick={() => handleAddSubcategory(v?._id)}
                            fontSize={'14px'}
                            fontWeight={'600'}
                            border={'1px solid #1A1B1A'}
                            borderRadius={'8px'}
                          >
                            Add New Subcatory
                          </Button>
                        </AccordionPanel>
                      </AccordionItem>
                    ))}
                </Accordion>
              )}
            </Stack>

            {/* <Stack display={'none'}>
              <Heading fontSize={'30px'} fontWeight={'700'}>
                Tags
              </Heading>
              <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
                Choose at least (1) tag:
              </Text>
            </Stack>
            <Accordion allowToggle>
              <AccordionItem
                mb={3}
                border={'1px solid #B4B4B4'}
                background={'#F3F3F3'}
                borderRadius={'8px'}
              >
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  h={'62px'}
                  borderBottom={'1px solid #B4B4B4'}
                  py={4}
                >
                  <Box px={4}>
                    <Heading fontSize={'20px'} fontWeight={'600'}>
                      Add New Tag
                    </Heading>
                  </Box>

                  <AccordionButton width={'max-content'}>
                    <AccordionIcon />
                  </AccordionButton>
                </Box>
                <AccordionPanel
                  display={'flex'}
                  flexDirection={'column'}
                  gap={4}
                >
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={3}
                  >
                    <Input
                      border={'1px solid #1A1B1A'}
                      bg={'#F3F3F3'}
                      borderRadius={'8px'}
                      id="tagName"
                      placeholder="Enter Here"
                    />
                    <Button
                      bg={
                        'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      }
                      onClick={handleAddTag}
                      color={'#000'}
                      width={'max-content'}
                    >
                      Add
                    </Button>
                  </Stack>
                  <Stack
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    gap={3}
                    flexWrap={'wrap'}
                  >
                    {tags.map((item, index) => (
                      <Box w="48%">
                        <Checkbox
                          colorScheme={'green'}
                          fontSize={'18px'}
                          fontWeight={'500'}
                          isChecked={
                            productId
                              ? editProduct?.tags?.some(
                                  product => product === item
                                )
                              : editProduct?.checkedTags?.some(
                                  product => product === item
                                )
                          }
                          onChange={e => handleCheckboxTagChange(e, item)}
                        >
                          {item}
                        </Checkbox>
                      </Box>
                    ))}
                  </Stack>
                </AccordionPanel>
              </AccordionItem>
            </Accordion> */}
          </Stack>
        </SimpleGrid>
      </form>
    </Stack>
  );
}
