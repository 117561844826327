import { useDispatch, useSelector } from 'react-redux';
import { addToCarts, selectCartData } from '../reducers/slices/cart.slice';
import { POST } from './ApiProvider';
import { useToast } from '@chakra-ui/react';
import {
  addWishlist,
  selectWebsiteData,
  selectWishlistData,
  setWebsiteData,
  setWishlist,
  setProductDetail,
} from '../reducers/slices/website.slice';
import { set } from 'lodash';
import { useState } from 'react';

const storeId = localStorage.getItem('storeId');

const useAddToCart = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const user = useSelector(state => state.user.value);
  const cart = useSelector(selectCartData);
  const [loading, setLoading] = useState(false);
  console.log(cart);
  // const addToCart = async product => {
  //   try {
  //     let itemInCart = {
  //       productId: product._id,
  //       quantity: 1,
  //       price: product?.isDiscount ? product.discountedPrice : product.price,
  //       salePrice: product.sale_price || product.saleprice || 0,
  //       discount: product?.isDiscount ? product.discountedPrice : 0,
  //       productName: product.name,
  //       total: product?.isDiscount ? product.discountedPrice : product.price,
  //       productImage: product?.images ? product.images[0]?.path : product.url,
  //       stock: product.stock,
  //     };

  //     if (user === undefined || user === null) {
  //       dispatch(addToCarts(itemInCart));
  //       let check = cart?.find(item => item.productId === itemInCart.productId);
  //       console.log(check?.quantity, check?.stock);
  //       if (check?.quantity == check?.stock) {
  //         console.log('out of stock');
  //       } else {
  //         toast({
  //           description: 'Product added to cart successfully',
  //           status: 'success',
  //           position: 'top-right',
  //           duration: 5000,
  //           isClosable: true,
  //         });
  //       }
  //     } else {
  //       let itemInCart = {
  //         productId: product._id,
  //         quantity: 1,
  //         price: product?.isDiscount ? product.discountedPrice : product.price,
  //         salePrice: product.sale_price || product.saleprice || 0,
  //         discount: product?.isDiscount ? product.discountedPrice : 0,
  //         productName: product.name,
  //         total: product?.isDiscount ? product.discountedPrice : product.price,
  //         productImage: product?.images ? product.images[0]?.path : product.url,
  //         stock: product.stock,
  //       };
  //       const response = await POST('/store/cart', {
  //         storeId: product.storeId || localStorage.getItem('storeId'),
  //         cartItems: itemInCart,
  //       });

  //       if (response.status === 'success' && response.data) {
  //         dispatch(addToCarts(itemInCart));

  //         toast({
  //           description: 'Product added to cart successfully',
  //           status: 'success',
  //           position: 'top-right',
  //           duration: 5000,
  //           isClosable: true,
  //         });
  //       } else {
  //         toast({
  //           description: response?.message,
  //           status: 'error',
  //           position: 'top-right',
  //           duration: 5000,
  //           isClosable: true,
  //         });
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const addToCart = async product => {
    try {
      let itemInCart = {
        productId: product._id,
        quantity: 1,
        price: product?.isDiscount ? product.discountedPrice : product.price,
        salePrice: product.sale_price || product.saleprice || 0,
        discount: product?.isDiscount ? product.discountedPrice : 0,
        productName: product.name,
        total: product?.isDiscount ? product.discountedPrice : product.price,
        productImage: product?.images ? product.images[0]?.path : product.url,
        stock: product.stock,
      };

      let check = cart?.cartItems
        ? cart?.cartItems?.find(
            item => item?.productId == itemInCart?.productId
          )
        : cart?.find(item => item?.productId == itemInCart?.productId);

      if (check) {
        if (check.quantity == check.stock) {
          console.log('Out of stock');
          toast({
            description: 'Product is out of stock',
            status: 'warning',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      if (user === undefined || user === null) {
        dispatch(addToCarts(itemInCart));
        toast({
          description: 'Product added to cart successfully',
          status: 'success',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      } else {
        setLoading(true);
        const response = await POST('/store/cart', {
          storeId: product.storeId || localStorage.getItem('storeId'),
          cartItems: itemInCart,
        });
        console.log(cart);
        let check = cart?.cartItems
          ? cart?.cartItems?.find(
              item => item?.productId == itemInCart?.productId
            )
          : cart?.find(item => item?.productId == itemInCart?.productId);

        if (check) {
          if (check.quantity == check.stock) {
            setLoading(false);
            console.log('Out of stock');
            toast({
              description: 'Product is out of stock',
              status: 'warning',
              position: 'top-right',
              duration: 5000,
              isClosable: true,
            });
            return;
          }
        }

        if (response.status === 'success' && response.data) {
          setLoading(false);

          dispatch(addToCarts(itemInCart));
          toast({
            description: 'Product added to cart successfully',
            status: 'success',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        } else {
          setLoading(false);

          toast({
            description: response?.message,
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  return { addToCart, loading };
};

const useAddToWhishlist = () => {
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const websiteData = useSelector(selectWebsiteData);
  const user = useSelector(state => state.user.value);
  const wishlist = useSelector(selectWishlistData);
  const handleFavourite = async (product, value, selectedTab) => {
    setLoading(true);
    try {
      if (value) {
        console.log(product, 'Top Rated');
        // let { products } = websiteData;
        // let newData = products[selectedTab].map(item => {
        //   if (item._id === product._id) {
        //     if (item.is_favourite === true) {
        //       dispatch(setWishlist(wishlist.filter(v => v._id !== item._id)));
        //     } else if (item.is_favourite === false) {
        //       dispatch(addWishlist(product));
        //     }

        //     return { ...item, is_favourite: !item.is_favourite };
        //   }
        //   return item;
        // });

        // let temp = {
        //   ...websiteData,
        //   products: {
        //     ...websiteData.products,
        //     [selectedTab]: newData,
        //   },
        // };

        // dispatch(setWebsiteData(temp));
        let { products } = websiteData;

        // Update the selected tab's products
        let newData = products[selectedTab]?.map(item => {
          if (item._id === product._id) {
            // Update the wishlist based on the current favorite status
            if (item.is_favourite) {
              dispatch(setWishlist(wishlist.filter(v => v._id !== item._id)));
            } else {
              dispatch(addWishlist(product));
            }

            // Toggle the favorite status
            return { ...item, is_favourite: !item.is_favourite };
          }
          return item;
        });

        // Update the websiteData with the modified products array
        let updatedWebsiteData = {
          ...websiteData,
          products: {
            ...products,
            [selectedTab]: newData,
          },
        };

        // Dispatch the updated website data
        dispatch(setWebsiteData(updatedWebsiteData));
      } else if (value === false) {
        // let { arrproducts } = websiteData;
        // arrproducts = arrproducts.map(item => {
        //   if (item._id === product._id) {
        //     return { ...item, is_favourite: !item.is_favourite };
        //   }
        //   return item;
        // });
        // let temp = { ...websiteData };
        // temp['arrproducts'] = arrproducts;
        // console.log(temp['arrproducts']);
        // dispatch(setWebsiteData(temp));

        // dispatch(addWishlist(product));
        let { arrproducts } = websiteData;

        // Toggle the `is_favourite` property for the selected product
        arrproducts = arrproducts.map(item => {
          if (item._id === product._id) {
            return { ...item, is_favourite: !item.is_favourite };
          }
          return item;
        });

        // Create a new state object and update the `arrproducts` property
        let updatedWebsiteData = { ...websiteData, arrproducts };

        // Log the updated `arrproducts` for debugging
        console.log(updatedWebsiteData.arrproducts);

        // Dispatch the updated website data
        dispatch(setWebsiteData(updatedWebsiteData));

        // Check if the updated product is now a favorite
        const isFavourite = arrproducts.find(
          v => v._id === product._id
        )?.is_favourite;
        console.log(isFavourite, 'isFavourite checking');
        // Add the product to the wishlist if it is marked as favorite
        if (isFavourite) {
          dispatch(addWishlist(product));
        } else {
          // checking if not working then hide it//
          dispatch(addWishlist(product));
        }
      } else if (value === null && selectedTab === 'detail') {
        // let temp1 = { ...product, is_favourite: !product.is_favourite };
        // let { arrproducts } = websiteData;
        // arrproducts = arrproducts.map(item => {
        //   if (item._id === product._id) {
        //     return { ...item, is_favourite: !item.is_favourite };
        //   }
        //   return item;
        // });
        // const addDataForWishlist = arrproducts.filter(
        //   v => v._id === product._id
        // )[0];
        // let temp = { ...websiteData };
        // temp['arrproducts'] = arrproducts;
        // console.log(temp['arrproducts'], 'checking');

        // dispatch(setWebsiteData(temp));
        // dispatch(setProductDetail(temp1));
        // dispatch(addWishlist(addDataForWishlist));
        // Toggle the `is_favourite` status for the product
        let temp1 = { ...product, is_favourite: !product.is_favourite };

        // Get the `arrproducts` from `websiteData` and update the relevant product
        let { arrproducts } = websiteData;
        arrproducts = arrproducts?.map(item => {
          if (item._id === product._id) {
            return { ...item, is_favourite: !item.is_favourite };
          }
          return item;
        });

        // Find the updated product to add to the wishlist
        const addDataForWishlist = arrproducts?.find(
          v => v._id === product._id
        );

        // Create a new state object and update the `arrproducts` property
        let updatedWebsiteData = { ...websiteData, arrproducts };

        // Log the updated `arrproducts` for debugging
        console.log(updatedWebsiteData.arrproducts, 'checking');

        // Dispatch the updated website data
        dispatch(setWebsiteData(updatedWebsiteData));

        // Dispatch the updated product details
        dispatch(setProductDetail(temp1));

        // Add the updated product to the wishlist
        dispatch(addWishlist(addDataForWishlist));
      } else {
        if (product.is_favourite === true) {
          dispatch(setWishlist(wishlist?.filter(v => v._id !== product._id)));
        } else {
          dispatch(addWishlist(product));
        }
      }
      // let { products } = websiteData;
      // let newData = products[selectedTab].map(item => {
      //   if (item._id === product._id) {
      //     if (item.is_favourite === true) {
      //       dispatch(setWishlist(wishlist.filter(v => v._id !== item._id)));
      //     } else if (item.is_favourite === false) {
      //       dispatch(addWishlist(product));
      //     }

      //     return { ...item, is_favourite: !item.is_favourite };
      //   }
      //   return item;
      // });

      // let temp = {
      //   ...websiteData,
      //   products: {
      //     ...websiteData.products,
      //     [selectedTab]: newData,
      //   },
      // };

      // dispatch(setWebsiteData(temp));

      const formData = new FormData();
      formData.append('productId', product._id);
      formData.append('storeId', product.storeId);
      if (user) {
        const response = await POST('/store/favourite', formData);

        if (response.status === 'success' && response.data) {
          console.log(response.data, 'check');
          setLoading(false);
          toast({
            description: `Product ${
              response.data.is_favourite
                ? 'added to wishlist'
                : 'removed from wishlist'
            } `,
            status: 'success',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        } else {
          setLoading(false);

          toast({
            description: response?.message,
            status: 'error',
            position: 'top-right',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { handleFavourite, loading };
};
function convertDate(utcDateStr, check = null) {
  console.log(utcDateStr, check, 'arham');
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const date = new Date(utcDateStr);

  const day = String(date.getDate()).padStart(2, '0');
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
}
function convertNumbertoCommaSeperate(number) {
  return number.toLocaleString(undefined, { maximumFractionDigits: 2 });
}
export {
  useAddToCart,
  useAddToWhishlist,
  convertNumbertoCommaSeperate,
  convertDate,
};
