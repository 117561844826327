import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Tag,
  Icon,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  useToast,
  Divider,
  calc,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { dateTime } from '../../../assets/data/Data';
import { IoTimeOutline } from 'react-icons/io5';
import Cele from '../../../assets/images/website/cele.png';
import { Link, useNavigate } from 'react-router-dom';
import { GET, POST } from '../../../utilities/ApiProvider';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCheckoutDetails,
  setCarts,
  setCheckoutDetails,
} from '../../../reducers/slices/cart.slice';
import { setBuyNow } from '../../../reducers/slices/website.slice';
import { convertNumbertoCommaSeperate } from '../../../utilities/helper';
export default function Delivery() {
  const [datelist, setdatelist] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [timeDeliverySlot, setTimeDeliverySlot] = useState(null);
  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const details = useSelector(selectCheckoutDetails);
  const [deliveryTime, setDeliveryTime] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  function getFormattedDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    async function fetchDeliveryOptions() {
      try {
        const response = await GET(
          `/store/delivery/${localStorage.getItem(
            'storeId'
          )}?currentDate=${moment().format('YYYY-MM-DD')}`
        );
        console.log(response);
        // const now = new Date('2024-08-02T00:05:00');
        const now = new Date();
        const hours = now.getHours();

        if (hours >= 0 && hours < 9) {
          console.log('yaha aya');
          let temp = response.data?.[0]?.deliveryFee;
          temp.splice(0, 1);
          setdatelist(temp);
        } else {
          console.log(response?.data[0]?.deliveryFee?.[0], 'date checking');
          setSelectedService(response?.data[0]?.deliveryFee?.[0]?._id);
          setDeliveryDate(response?.data[0]?.deliveryFee?.[0]?.date);
          setdatelist(response?.data[0]?.deliveryFee);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchDeliveryOptions();
  }, []);
  const placeOrder = async () => {
    onClose2();
    try {
      const temp = { ...details };
      console.log(temp, 'buy now checking');
      temp['deliveryId'] = deliveryTime;
      temp['deliveryDate'] = deliveryDate;
      temp['platform'] = 'website';

      // temp['deliveryId'] = selectedService;
      const response = await POST(`/store/order`, temp);
      console.log(response);
      setOrderDetails(response?.data);
      if (response.status === 'success') {
        onOpen();
        dispatch(setCarts([]));
        dispatch(setCheckoutDetails({}));
        dispatch(setBuyNow(null));
      } else {
        toast({
          description: response?.message,
          status: 'error',
          position: 'top-right',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // New//

    const currentTime = new Date();
    const timeSlots = datelist?.filter(item => item._id === selectedService)[0]
      ?.time;
    console.log(timeSlots);

    if (
      new Date(
        datelist?.filter(item => item._id === selectedService)[0]?.date
      )?.toDateString() === currentTime.toDateString()
    ) {
      console.log('enter');
      function parseTime(time) {
        const [hourMinute, period] = time.split(' ');
        let [hours, minutes] = hourMinute.split(':').map(Number);
        if (period === 'PM' && hours !== 12) hours += 12;
        if (period === 'AM' && hours === 12) hours = 0;
        return { hours, minutes };
      }

      function getTimeSlotRanges(orderTime) {
        const slots = [];
        const currentHour = orderTime.getHours();
        const currentMinute = orderTime.getMinutes();
        if (currentHour < 6) {
          return [];
        }
        // Special case: if the time is between 9:05 PM and 11:59 PM, show the slot from 12 AM to 3 AM
        if (
          (currentHour === 21 && currentMinute >= 5) ||
          (currentHour > 21 && currentHour < 24)
        ) {
          console.log(currentHour, currentMinute, 'push in this');
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              0,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              3,
              0,
              0
            ),
          });
        }

        // Define time slot ranges based on the current hour
        if (currentHour < 9) {
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              9,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              12,
              0,
              0
            ),
          });
        }
        if (currentHour < 12) {
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              12,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              15,
              0,
              0
            ),
          });
        }
        if (currentHour < 15) {
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              15,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              18,
              0,
              0
            ),
          });
        }
        if (currentHour < 18) {
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              18,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              21,
              0,
              0
            ),
          });
        }
        if (currentHour < 21) {
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              21,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate(),
              24,
              0,
              0
            ),
          });
        }

        // Special case for the early morning range
        if (currentHour >= 23 || currentHour < 3) {
          slots.push({
            start: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate() + 1,
              0,
              0,
              0
            ),
            end: new Date(
              orderTime.getFullYear(),
              orderTime.getMonth(),
              orderTime.getDate() + 1,
              3,
              0,
              0
            ),
          });
        }

        return slots;
      }

      function timeSlotsWithinRanges(timeSlots, ranges) {
        return ranges.flatMap(
          range =>
            timeSlots?.filter(slot => {
              const { hours, minutes } = parseTime(slot.start);
              const slotStartTime = new Date();
              slotStartTime.setHours(hours, minutes, 0, 0);
              return slotStartTime >= range.start && slotStartTime < range.end;
            }) || []
        );
      }

      const timeSlotRanges = getTimeSlotRanges(currentTime);
      const suggestedTimeSlots = timeSlotsWithinRanges(
        timeSlots,
        timeSlotRanges
      );
      console.log(suggestedTimeSlots);
      // if (suggestedTimeSlots.length === 0) {
      //   let temp = [...datelist];
      //   temp.splice(0, 1);
      //   setdatelist(temp);
      // } else {
      if (suggestedTimeSlots.length === 0) {
        console.log(datelist[1], 'date checking');
        setSelectedService(datelist[1]?._id);
        setDeliveryDate(datelist[1]?.date);
      }

      setTimeDeliverySlot(suggestedTimeSlots);
      // }
    } else {
      setTimeDeliverySlot(timeSlots);
    }
  }, [selectedService]);
  const calculateDeliverySlot = timeSlots => {
    console.log(timeSlots, deliveryTime);
    const data = timeSlots?.find(item => item._id === deliveryTime);
    console.log(data);
    return `${data?.start} - ${data?.end}`;
  };
  function convertTo12Hour(timeStr) {
    console.log({ timeStr });
    if (timeStr === null) {
      return null;
    }

    let [hours, minutes] = timeStr.split(':');
    hours = parseInt(hours, 10);

    // Determine the AM/PM modifier
    let modifier = 'AM';
    if (hours >= 12) {
      modifier = 'PM';
      if (hours > 12) {
        hours -= 12;
      }
    } else if (hours === 0) {
      hours = 12;
    }

    // Convert hours back to string for consistency
    hours = hours.toString();
    console.log({ hours, modifier });

    // Ensure hours and minutes are properly formatted
    const formattedTime = `${hours.padStart(2, '0')}:${minutes} `;
    console.log(formattedTime, 'completed time');
    return formattedTime;
  }
  return (
    <>
      <Modal isCentered isOpen={isOpen2} onClose={onClose2}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Stack gap={2} py={4} textAlign={'center'} alignItems={'center'}>
              <Image w={'100px'} src={Cele} />
              <Stack>
                <Heading fontSize={'30px'}>Are You Sure!</Heading>
                <Text fontSize={'14px'}>
                  Your order has been placed, and it will be delivered shortly.
                </Text>
              </Stack>
              <Stack direction={'row'} gap={4}>
                <Button
                  py={6}
                  fontSize={'16px'}
                  width={'200px'}
                  fontWeight={500}
                  color={'#fff'}
                  background={
                    'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                  }
                  _hover={{
                    color: '#000',
                  }}
                  onClick={() => placeOrder()}
                >
                  Submit Your Order
                </Button>
                <Button
                  as={Link}
                  to={'/'}
                  py={6}
                  fontSize={'16px'}
                  width={'200px'}
                  fontWeight={500}
                  color={'#fff'}
                  background={
                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  }
                  _hover={{
                    color: '#000',
                  }}
                >
                  Continue Shopping
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isOpen}
        onClose={() => {
          onClose();
          navigate('/');
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Stack py={4} textAlign={'center'} alignItems={'center'}>
              <Image w={'120px'} src={Cele} />
              <Heading fontSize={'24px'}>Your Order Has Been Placed</Heading>

              <Stack spacing={3} w={'full'}>
                <Stack
                  w={'full'}
                  borderTop={'1px solid #e7e0e0'}
                  spacing={2}
                  borderBottom={'1px solid #e7e0e0'}
                  py={4}
                >
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Order ID:
                    </Text>
                    <Text fontSize="15px" fontWeight={600}>
                      {orderDetails?.invoice}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Order Name:
                    </Text>
                    <Text fontSize="15px" fontWeight={600}>
                      {orderDetails?.name}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'row'}
                    justify={'space-between'}
                    alignItems={'center'}
                    // justifyContent={'center'}
                    // alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Order Email:
                    </Text>
                    <Text
                      noOfLines={1}
                      textAlign={'right'}
                      width={'200px'}
                      fontSize="15px"
                      fontWeight={600}
                    >
                      {orderDetails?.email}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Purchased Date:
                    </Text>
                    <Text fontSize="15px" fontWeight={600}>
                      {moment(orderDetails?.orderDate).format('DD-MMM-YYYY')}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Delivery Date:
                    </Text>
                    <Text fontSize="15px" fontWeight={600}>
                      {moment(orderDetails?.deliveryDate).format('DD-MMM-YYYY')}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Delivery Slot:
                    </Text>
                    <Text fontSize="15px" fontWeight={600}>
                      {calculateDeliverySlot(timeDeliverySlot)}
                    </Text>
                  </Stack>
                  <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Text fontSize="16px" fontWeight={600}>
                      Order Total:
                    </Text>
                    <Text fontSize="15px" fontWeight={600}>
                      {orderDetails?.total.toFixed(2)} Rs
                    </Text>
                  </Stack>
                </Stack>
              </Stack>
              <Text fontSize={'14px'}>
                Your order has been placed, and it will be delivered shortly.
              </Text>
              <Stack>
                <Button
                  as={Link}
                  to={'/'}
                  py={4}
                  fontSize={'16px'}
                  width={'200px'}
                  background={
                    'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                  }
                >
                  Continue Shopping
                </Button>
              </Stack>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Stack>
        <Container p={0} mb={6} maxW={{ base: '8xl', xl: '6xl', '2xl': '8xl' }}>
          <Stack px={16} bg={'#fff'} py={12} borderRadius={12} gap={8}>
            <Stack>
              <Heading color={'#111'} fontSize={'22px'} fontWeight={700}>
                Delivery Options
              </Heading>
              <Text>
                Available times: {moment(datelist?.[0]?.date).format('DD MMMM')}{' '}
                -{' '}
                {moment(datelist?.[datelist?.length - 1]?.date).format(
                  'DD MMMM'
                )}
              </Text>
            </Stack>
            <Stack>
              <SimpleGrid
                columns={{ base: '3', xl: '7', '2xl': '7' }}
                spacing={2}
              >
                {datelist?.length > 0 &&
                  datelist.map((v, k) => {
                    console.log(v, 'datelist');
                    return (
                      <Stack
                        onClick={() => {
                          setSelectedService(v?._id);
                          setDeliveryDate(v?.date);
                        }}
                        borderRadius={4}
                        cursor={'pointer'}
                        // display={
                        //   k === 0 && timeDeliverySlot?.length === 0
                        //     ? 'none'
                        //     : 'block'
                        // }
                        // display={
                        //   k === 0 && timeDeliverySlot?.length === 0
                        //     ? 'none'
                        //     : 'block'
                        // }
                        border={
                          selectedService === v?._id
                            ? '1px solid #007341'
                            : 'none'
                        }
                      >
                        <Box
                          bg={
                            selectedService === v?._id
                              ? 'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              : 'rgba(0, 115, 65, 0.2)'
                          }
                        >
                          <Text
                            textAlign={'center'}
                            py={2}
                            color={'#fff'}
                            fontSize={'16px'}
                            fontWeight={600}
                          >
                            {k == 0 ? 'Today' : k == 1 ? 'Tomorrow' : v.day}
                          </Text>
                        </Box>
                        <Text pb={3} textAlign={'center'}>
                          {moment(v.date).format('DD MMMM ')}
                        </Text>
                      </Stack>
                    );
                  })}
              </SimpleGrid>
            </Stack>
            <Stack>
              <Accordion>
                {timeDeliverySlot?.length > 0 ? (
                  timeDeliverySlot?.map((v, k) => {
                    console.log(v, 'timeDeliverySlot');
                    return (
                      <AccordionItem>
                        <Box
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          py={4}
                        >
                          <Box
                            width={'90%'}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            flexWrap={'wrap'}
                            gap={2}
                          >
                            <Text>
                              {convertTo12Hour(v?.start)} -{' '}
                              {convertTo12Hour(v?.end)}
                            </Text>
                            {/* <Tag
                              bg={'#fff'}
                              // bg={
                              //   'var(--New-Gradient-2, radial-gradient(224.21% 224.21% at -16.41% -106.24%, #FFF 0%, #E2E432 28%, #399A48 80%))'
                              // }
                              color={'#000'}
                              px={3}
                              py={2}
                            >
                              First available delivery time!
                            </Tag> */}
                            {/* <Text>
                              <Icon
                                mr={2}
                                verticalAlign={'middle'}
                                as={IoTimeOutline}
                              />
                              Book before 11:15am
                            </Text> */}
                            <Button
                              width={'max-content'}
                              fontSize={'16px'}
                              padding={'10px 25px'}
                              background={
                                'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                              }
                              onClick={() => {
                                console.log(v);
                                setDeliveryTime(v?._id);
                                onOpen2();
                              }}
                            >
                              Place Order
                            </Button>
                          </Box>
                          {/* <AccordionButton bg={'#e7e0e0'} width={'max-content'}>
                            <AccordionIcon />
                          </AccordionButton> */}
                        </Box>
                        <AccordionPanel py={6} px={0}>
                          <SimpleGrid
                            columns={{ base: '1', xl: '2', '2xl': '2' }}
                            spacing={2}
                          >
                            <Stack gap={1}>
                              <Heading fontSize={'17px'}>
                                Can I cancel or amend a same day delivery?
                              </Heading>
                              <Text fontSize={'16px'}>
                                Once you have placed your Same Day order you
                                won’t be able to edit it
                              </Text>
                            </Stack>
                            <Stack gap={1}>
                              <Heading fontSize={'17px'}>
                                How much is same day delivery?
                              </Heading>
                              <Text fontSize={'16px'}>
                                £3.00*, £25 min order value
                              </Text>
                            </Stack>
                          </SimpleGrid>
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })
                ) : (
                  <Text>{selectedService && 'No Available Time Slots'}</Text>
                )}
              </Accordion>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
}
