import {
  Box,
  Flex,
  Text,
  Heading,
  FormLabel,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  useDisclosure,
  Image,
  FormControl,
  SimpleGrid,
  Spinner,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useToast,
  CheckboxGroup,
  Checkbox,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb,
} from '@chakra-ui/react';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import Star from '../../../assets/images/website/star.svg';
import wishimg from '../../../assets/images/website/wishlist.jpg';
import { Link } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { GET, POST } from '../../../utilities/ApiProvider';
import { imgUrl } from '../../../utilities/config';
import { convertNumbertoCommaSeperate } from '../../../utilities/helper';
import Pagination from '../../../utilities/Pagination';
import ModalWrapper from '../../../components/Modal/';
import { AddIcon } from '@chakra-ui/icons';
import { debounce, set } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { io } from 'socket.io-client';
import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import ReactStars from 'react-rating-stars-component';

function AddProductCsv({ fetchData, onClose, setDisable }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const socket = io('wss://ajwamart.pk', {
    path: '/socket.io',
    transports: ['websocket'],
    EIO: 4,
  });

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket connected:', socket.id);
    });

    socket.on('connect_error', error => {
      console.log('Socket connection error:', error);
    });

    return () => {
      socket.disconnect();
    };
  }, []);
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };
  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('files', file);
      const response = await POST('/admin/product/uploader', formData);
      if (
        response.status === 'success' ||
        response.data === 'upload successfully'
      ) {
        setDisable(true);
        socket.emit('logs');
        socket.on('logs', data => {
          console.log(data, 'data');
          if (data.data) {
            document.getElementById('total_doc').style.color = '#07d57c';
            document.getElementById('total_doc').innerHTML =
              data.data.success + ' products added successfully';
          } else if (data.data === null) {
            document.getElementById('total_doc').style.color = '#07d57c';
            document.getElementById('total_doc').innerHTML =
              'All Products Added Successfully';
            setTimeout(() => {
              setDisable(false);
              document.getElementById('total_doc').innerHTML = '';
            }, 3000);
          }
        });

        fetchData();
        setLoading(false);
        toast({
          title: 'Upload successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      } else {
        toast({
          title: `${response.message}`,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
        console.log('Error while uploading csv file', response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Upload CSV File
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('upload').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload CSV File
                </Text>
              </Box>
              <Input
                onChange={handleFileChange}
                type="file"
                display="none"
                id="upload"
                accept=".csv"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file && <Text marginTop="10px">{file.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
            isLoading={loading}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function EditProductCsv({ fetchData, onClose, setDisable }) {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const [selectedService, setSelectedService] = useState('3');
  const handleFileChange = event => {
    setFile(event.target.files[0]);
  };

  const data = [
    {
      id: '1',
      label: 'Price only',
      value: 'price',
    },
    {
      id: '2',
      label: 'Stock only',
      value: 'stock',
    },
    {
      id: '3',
      label: 'Both Price and Stock',
      value: 'stock',
    },
  ];
  const socket = io('wss://ajwamart.pk', {
    path: '/socket.io',
    transports: ['websocket'],
    EIO: 4,
  });

  // const socket = io('ws://ajwamart.pk');

  // useEffect(() => {
  //   socket.on('connect', () => {
  //     console.log('Socket connected:', socket.id);
  //   });
  // socket.emit('logs');
  // socket.on('logs', data => {
  //   console.log(data, 'data checking');
  // });

  // socket.on('connect_error', error => {
  //   console.log('Socket connection error:', error);
  // });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);
  const handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    try {
      const formData = new FormData(e.target);
      formData.append('files', file);
      formData.append('type', 'update');
      if (selectedService === '1') {
        formData.append('reqColumn', JSON.stringify(['sku', 'price']));
      } else if (selectedService === '2') {
        formData.append('reqColumn', JSON.stringify(['sku', 'stock']));
      } else if (selectedService === '3') {
        formData.append('reqColumn', JSON.stringify(['sku', 'price', 'stock']));
      }

      const response = await POST(`/admin/product/uploader`, formData);
      // console.log(response);
      // console.log(socket, 'checking');
      if (
        response.status === 'success' ||
        response.data === 'upload successfully'
      ) {
        socket.emit('logs');
        setDisable(true);
        socket.on('logs', data => {
          if (data.data) {
            document.getElementById('total_doc').style.color = '#07d57c';
            document.getElementById('total_doc').innerHTML =
              data.data.success + ' products updated successfully';
          } else if (data.data === null) {
            document.getElementById('total_doc').style.color = '#07d57c';
            document.getElementById('total_doc').innerHTML =
              'All Products Updated Successfully';
            setTimeout(() => {
              setDisable(false);
              document.getElementById('total_doc').innerHTML = '';
            }, 3000);
          }
        });

        fetchData();
        setLoading(false);
        toast({
          title: 'Upload successfully',
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
      } else {
        setLoading(false);
        toast({
          title: `${response.message}`,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        });
        onClose();
        console.log('Error while uploading csv file', response);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack display={'flex'} flexDirection={'column'} gap={3}>
        <FormControl>
          <FormLabel fontSize={'16px'} fontWeight={'500'} lineHeight={'30px'}>
            Upload CSV File
          </FormLabel>
          <Box
            h="56px"
            borderRadius={'8px'}
            border={'1px dashed #B4B4B4'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            cursor={'pointer'}
            onClick={() => document.getElementById('upload').click()}
          >
            <Stack
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={3}
            >
              <Box
                w="24px"
                h="24px"
                borderRadius={'50%'}
                border={'1px solid #1A1A1A'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                color="#1A1A1A"
              >
                <AddIcon fontSize={'12px'} />
              </Box>
              <Box marginTop={'0px !important'}>
                <Text fontSize={'18px'} fontWeight={'500'}>
                  Upload CSV File
                </Text>
              </Box>
              <Input
                onChange={handleFileChange}
                type="file"
                display="none"
                id="upload"
                accept=".csv"
              />
              <label htmlFor="upload">
                <Box
                  as="span"
                  display={'none'}
                  cursor="pointer"
                  padding="10px 20px"
                  borderRadius="8px"
                  border="1px solid #B4B4B4"
                  background="#F3F3F3"
                  _hover={{
                    background: '#E2E2E2',
                  }}
                >
                  Choose File
                </Box>
              </label>
              {file && <Text marginTop="10px">{file.name}</Text>}
            </Stack>
          </Box>
        </FormControl>
        <Stack>
          <CheckboxGroup>
            <Stack direction={'row'} gap={3}>
              {data?.map((item, index) => (
                <Checkbox
                  onChange={() => setSelectedService(item.id)}
                  isChecked={selectedService === item.id}
                >
                  <Text fontSize={'15px'} fontWeight={'500'}>
                    {item.label}
                  </Text>
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        </Stack>
        <Stack py={3}>
          <Button
            padding={'10px 50px'}
            type="submit"
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%)) '
            }
            color={'#fff'}
            fontWeight={'500'}
            lineHeight={'inherit'}
            borderRadius={12}
            h="45px"
            _hover={{
              color: '#000',
            }}
            id="btn-continue"
            isLoading={loading}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default function Productlist() {
  const [selectedService, setSelectedService] = useState('all');
  const { onOpen, onClose, isOpen } = useDisclosure();
  const {
    onOpen: onOpen2,
    onClose: onClose2,
    isOpen: isOpen2,
  } = useDisclosure();
  const toast = useToast();
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentSearchPage, setCurrentSearchPage] = useState(1);
  const [totalSearchItems, setTotalSearchItems] = useState(0);
  const [results, setResults] = useState(null);
  const inputRef = useRef();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [availability, setAvailability] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [Open, setOpen] = useState(false);
  const [disable, setDisable] = useState(false);
  const [applyingFilter, setApplyingFilter] = useState(false);

  const navigate = useNavigate();
  const socket = io('wss://ajwamart.pk', {
    path: '/socket.io',
    transports: ['websocket'],
    EIO: 4,
  });
  const ref = useRef();
  let itemsPerPage = 20;

  const handleChange = value => {
    setSortOrder(value);
  };

  const handleAvailabilityChange = value => {
    setAvailability(value);
  };
  async function fetchData(filters = '') {
    try {
      setLoading(true);

      const storeId = localStorage.getItem('storeId');
      const skip = (currentPage - 1) * itemsPerPage;
      const limit = itemsPerPage;

      // Construct the URL with filters
      const url = `/admin/product?skip=${skip}&limit=${limit}&storeId=${storeId}${filters}`;

      const response = await GET(url);

      window.scrollTo({ top: 0, behavior: 'smooth' });
      if (response.status === 'success') {
        setData(response);
        setTotalItems(response?.count);
        setLoading(false);
        setApplyingFilter(false);
        setIsFiltering(false);
      } else {
        setLoading(false);
        setData({});
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const fetchResults = async (query, page = 1) => {
    if (!query) {
      setIsSearching(false);
      setCurrentSearchPage(1);
      return;
    }
    setIsSearching(true);
    setIsSearchLoading(true);
    try {
      const response = await GET(
        `/store/product?storeId=${localStorage.getItem(
          'storeId'
        )}&searchQuery=${query}&skip=${
          (page - 1) * itemsPerPage
        }&limit=${itemsPerPage}`
      );
      window.scrollTo({ top: 0, behavior: 'smooth' });

      if (response.status === 'success') {
        setIsSearchLoading(false);
        setResults(response.data);
        setTotalSearchItems(response.count || 0);
        setOpen(true);
      } else {
        setResults([]);
        setIsSearching(false);
        setIsSearchLoading(false);

        console.log('Error while fetching results');
      }
    } catch (error) {
      setIsSearching(false);
      setIsSearchLoading(false);

      console.log(error, 'error while fetching results');
    }
  };
  async function fetchCategories() {
    try {
      const response = await GET('/admin/groceries');
      if (response.status === 'success') {
        console.log(response.data);
        setCategories(response.data);
      } else {
        setCategories([]);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const debouncedFetchResults = useCallback(debounce(fetchResults, 300), []);

  const handleInputChange = e => {
    const query = e.target.value;
    setSearchQuery(query);
    debouncedFetchResults(query);
  };

  useEffect(() => {
    let filters = '';
    if (selectedCategories.length > 0) {
      filters += `&subcategories=${selectedCategories.join(',')}`;
    }
    if (availability) {
      filters += `&stock=${availability}`;
    }
    if (sortOrder) {
      filters += `&sortBy=${sortOrder}`;
    }
    fetchData(filters);
  }, [currentPage]);
  useEffect(() => {
    fetchCategories();
    socket.emit('logs');
    socket.on('logs', data => {
      if (data.data) {
        ref.current = data.data.success;
        document.getElementById('total_doc').style.color = '#07d57c';

        document.getElementById('total_doc').innerHTML =
          data.data.success + ' products updated successfully';
      } else if (data.data === null) {
        document.getElementById('total_doc').style.color = '#07d57c';
        document.getElementById('total_doc').innerHTML =
          'All Products Updated Successfully';
        setTimeout(() => {
          setDisable(false);
          document.getElementById('total_doc').innerHTML = '';
        }, 3000);
      }
    });
  }, []);
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [searchQuery, results]);
  const handleSubcategoryChange = (event, categoryId) => {
    if (event.target.checked) {
      setSelectedCategories([...selectedCategories, categoryId]);
    } else {
      setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
    }
  };
  const applyFilter = () => {
    let filters = '';
    setApplyingFilter(true);
    if (selectedCategories.length > 0) {
      filters += `&subcategories=${selectedCategories.join(',')}`;
    }
    if (availability) {
      filters += `&stock=${availability}`;
    }
    if (sortOrder) {
      filters += `&sortBy=${sortOrder}`;
    }
    fetchData(filters);
  };
  const clearFilter = () => {
    setApplyingFilter(true);
    setSelectedCategories([]);
    setAvailability(null);
    setSortOrder(null);
    if (currentPage === 1) {
      fetchData();
    } else {
      setCurrentPage(1);
    }
  };

  return (
    <Stack gap={6} px={4} pb={6}>
      <ModalWrapper
        isOpen={isOpen}
        onClose={onClose}
        title={'Add Bulk Products'}
        children={
          <AddProductCsv
            fetchData={fetchData}
            onClose={onClose}
            setDisable={setDisable}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <ModalWrapper
        isOpen={isOpen2}
        onClose={onClose2}
        title={'Edit Bulk Products'}
        children={
          <EditProductCsv
            fetchData={fetchData}
            onClose={onClose2}
            setDisable={setDisable}
          />
        }
        size={'lg'}
        isCentered={true}
      />
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
        alignItems={'end'}
        gap={{ base: '4', sm: '4', md: '4', xl: '0', '2xl': '0' }}
      >
        <Stack>
          <Heading fontSize={'30px'} fontWeight={'700'}>
            Products
          </Heading>
          {/* <Text fontSize={'15px'} color={'#1A1B1A'} fontWeight={'400'}>
            Here is the all user details...Check Now!
          </Text> */}
        </Stack>
        <Stack
          position={'relative'}
          w={{ base: '100%', xl: '350px', '2xl': '40%' }}
          height={'45px'}
          spacing={4}
        >
          <FormControl>
            <Input
              type="text"
              placeholder="Search here..."
              padding="24px 30px"
              bg="#EDF5EC"
              value={searchQuery}
              onChange={handleInputChange}
              ref={inputRef}
            />
            <Button
              bg="transparent"
              top="0"
              bottom="0"
              margin="auto"
              position="absolute"
              right={0}
              zIndex={1}
            >
              <Icon color="#197540" fontSize="20px" as={BsSearch} />
            </Button>
          </FormControl>
          {/* {Open && (
            <Box position={'relative'}>
              <Menu isOpen={Open} onClose={() => setOpen(false)}>
                <MenuList
                  position="absolute"
                  minWidth={{
                    base: '90vw',
                    sm: '90vw',
                    lg: '95vw',
                    xl: '25vw',
                    '2xl': '23vw',
                  }}
                  maxH="300px"
                  overflowY="auto"
                  zIndex={9}
                >
                  {results.length > 0 ? (
                    results.map((result, index) => (
                      <MenuItem
                        onClick={() =>
                          navigate(`/dashboard/productdetails/${result._id}`)
                        }
                        key={index}
                      >
                        {result.name}{' '}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No results found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </Box>
          )} */}
        </Stack>
        <Stack direction={'row'}>
          <Button
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
            }
            border={'2px solid #07d57c'}
            padding={'10px 20px'}
            fontSize={'15px'}
            onClick={onOpen2}
            width={'max-content'}
            disabled={disable || ref.current > 0}
          >
            Edit Bulk Products
          </Button>
          <Button
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
            }
            border={'2px solid #07d57c'}
            padding={'10px 20px'}
            fontSize={'15px'}
            onClick={onOpen}
            width={'max-content'}
            disabled={disable || ref.current > 0}
          >
            Add Bulk Products
          </Button>
          <Button
            as={Link}
            to={'/dashboard/addproducts'}
            bg={
              'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
            }
            border={'2px solid #07d57c'}
            padding={'10px 20px'}
            fontSize={'15px'}
            width={'max-content'}
          >
            Add New Product
          </Button>
        </Stack>
      </Stack>

      {(!data && !results) || applyingFilter ? (
        <Stack
          w={'100%'}
          h={'80vh'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Spinner />
        </Stack>
      ) : (
        <>
          {/* <Stack gap={2}> */}
          {/* <Heading fontSize={'18px'} fontWeight={'500'}>
              Categories:
            </Heading> */}
          {/* <Stack
              direction={'row'}
              alignItems={'center'}
              gap={4}
              flexWrap={'wrap'}
            >
              {Object.keys(data)?.map(item => (
                <Button
                  border={
                    selectedService === item
                      ? '2px solid #EAA13E'
                      : '2px solid #07d57c'
                  }
                  padding={'10px 25px'}
                  bg={
                    selectedService === item
                      ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                      : 'transparent'
                  }
                  width={'max-content'}
                  _hover={{
                    color: '#000',
                    bg:
                      selectedService === item
                        ? 'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                        : 'transparent',
                  }}
                  onClick={() => setSelectedService(item)}
                >
                  {item}
                </Button>
              ))}
            </Stack> */}
          {/* </Stack> */}

          <Stack gap={2}>
            <Stack
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
            >
              <Heading fontSize={'18px'} fontWeight={'500'}>
                You’ve{' '}
                {isSearching
                  ? convertNumbertoCommaSeperate(totalSearchItems)
                  : convertNumbertoCommaSeperate(data?.count)}{' '}
                products
              </Heading>
              <h2 id="total_doc" color="#07d57c"></h2>
            </Stack>
            {searchQuery && !results && (
              <Stack
                w={'100%'}
                h={'60vh'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Spinner />
              </Stack>
            )}

            <Stack my={4}>
              <Button onClick={() => setIsFiltering(!isFiltering)}>
                Filters{' '}
                {isFiltering ? (
                  <ChevronUpIcon mx={2} />
                ) : (
                  <ChevronDownIcon mx={2} />
                )}
              </Button>
            </Stack>
            {isFiltering && (
              <Stack
                width={'100%'}
                gap={4}
                px={3}
                boxShadow="xl"
                borderRadius={8}
                py={6}
              >
                <Stack>
                  <Heading
                    color={'#409D47;'}
                    fontSize={'18px'}
                    fontWeight={700}
                  >
                    Availability
                  </Heading>
                  <Stack flexDirection="row" alignItems="end" gap={4}>
                    <Checkbox
                      colorScheme="green"
                      isChecked={availability === 'true'}
                      onChange={() => handleAvailabilityChange('true')}
                    >
                      In Stock
                    </Checkbox>
                    <Checkbox
                      colorScheme="green"
                      isChecked={availability === 'false'}
                      onChange={() => handleAvailabilityChange('false')}
                    >
                      Out of Stock
                    </Checkbox>
                  </Stack>
                </Stack>
                <Stack>
                  <Heading
                    color={'#409D47;'}
                    fontSize={'18px'}
                    fontWeight={700}
                  >
                    SortBy
                  </Heading>
                  <Stack flexDirection="row" alignItems="end" gap={4}>
                    <Checkbox
                      colorScheme="green"
                      isChecked={sortOrder === 'price-asc'}
                      onChange={() => handleChange('price-asc')}
                    >
                      Low to High
                    </Checkbox>
                    <Checkbox
                      colorScheme="green"
                      isChecked={sortOrder === 'price-desc'}
                      onChange={() => handleChange('price-desc')}
                    >
                      High to Low
                    </Checkbox>
                  </Stack>
                </Stack>
                <Stack>
                  <Heading
                    color={'#409D47;'}
                    fontSize={'18px'}
                    fontWeight={700}
                  >
                    Categories
                  </Heading>
                  <Stack
                    flexDirection={'row'}
                    alignItems={'end'}
                    gap={4}
                    flexWrap={'wrap'}
                  >
                    {categories?.map((v, k) => (
                      <Checkbox
                        key={k}
                        isChecked={selectedCategories.includes(v._id)}
                        colorScheme="green"
                        onChange={e => handleSubcategoryChange(e, v._id)}
                      >
                        {v.name}
                      </Checkbox>
                    ))}
                  </Stack>
                </Stack>
                <Stack flexDirection={'row'} alignItems={'end'} gap={4}>
                  <Button
                    border={'2px solid #409D47'}
                    borderRadius={'25px'}
                    bg={
                      'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    }
                    onClick={applyFilter}
                  >
                    Apply Filter
                  </Button>
                  <Button
                    width={'max-content'}
                    border={'2px solid #409D47'}
                    borderRadius={'25px'}
                    bg={'crimson'}
                    // bg={
                    //   'var(--New-Gradient, radial-gradient(292.31% 292.31% at -20.05% -138.79%, #FFF 0%, #FBB03B 50%, #C4272E 100%))'
                    // }
                    onClick={clearFilter}
                  >
                    Clear Filter
                  </Button>
                </Stack>
              </Stack>
            )}

            <SimpleGrid
              columns={{ base: '1', md: '1', xl: '2', '2xl': '2' }}
              spacing={10}
            >
              {searchQuery && results && results.length === 0 && (
                <Stack
                  w={'100%'}
                  // h={'60vh'}
                  // justifyContent={'center'}
                  // alignItems={'center'}
                >
                  <Text fontSize={'16px'} fontWeight={'bold'} color={'#1A1B1A'}>
                    No results found
                  </Text>
                </Stack>
              )}
              {data?.data?.length === 0 && (
                <Text fontSize={'16px'} fontWeight={'bold'} color={'#1A1B1A'}>
                  No results found
                </Text>
              )}

              {(searchQuery ? results : data?.data)?.map(item => (
                <Stack borderRadius={8} gap={2} p={5} boxShadow="xl">
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                      Item No. {item.productId}
                    </Text>
                    <Text color={'#1A1B1A'} fontSize={'16px'} fontWeight={600}>
                      Category:{' '}
                      {item.categories.map(item => item.name).join(', ')}
                    </Text>
                  </Box>
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                      <Image
                        width={{
                          base: '50px',
                          md: '80px',
                          xl: '80px',
                          '2xl': '80px',
                        }}
                        src={
                          item?.images?.[0]?.path.includes('https')
                            ? item?.images?.[0]?.path
                            : `${imgUrl}${item?.images?.[0]?.path}`
                        }
                      />
                      <Box>
                        <Heading
                          textOverflow={'ellipsis'}
                          width={'330px'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                          color={'#111'}
                          fontWeight={500}
                          fontSize={'16px'}
                        >
                          {item.name}
                        </Heading>
                        <Flex>
                          <ReactStars
                            count={5}
                            value={item?.rating}
                            size={15}
                            isHalf={true}
                            edit={false}
                            activeColor="#ffd700"
                          />
                          <Text color={'#989BA2'} fontSize={'14px'}>
                            ({item?.rating?.toFixed(2)})
                          </Text>
                        </Flex>
                      </Box>
                    </Box>
                    <Stack direction={'row'} gap={1}>
                      <Button
                        as={Link}
                        border={'2px solid #07d57c'}
                        padding={'10px 20px'}
                        bg={'transparent'}
                        width={'max-content'}
                        fontSize={'15px'}
                        to={`/dashboard/edit/product/${item?._id}`}
                      >
                        Edit Item
                      </Button>
                      <Button
                        as={Link}
                        border={'2px solid #07d57c'}
                        padding={'10px 20px'}
                        bg={'transparent'}
                        width={'max-content'}
                        fontSize={'15px'}
                        to={`/dashboard/productdetails/${item._id}`}
                      >
                        View Item
                      </Button>
                    </Stack>
                  </Box>

                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    flexWrap={'wrap'}
                    alignItems={'center'}
                  >
                    <Box>
                      <Heading color={'#007341'} fontSize={'16px'}>
                        Price
                      </Heading>
                      <Text
                        color={'#1A1B1A'}
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        Rs : {item.price}
                      </Text>
                    </Box>
                    <Box>
                      <Heading color={'#007341'} fontSize={'16px'}>
                        SKU
                      </Heading>
                      <Text
                        color={'#1A1B1A'}
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        {item.sku}
                      </Text>
                    </Box>
                    <Box>
                      <Heading color={'#007341'} fontSize={'16px'}>
                        Quantity
                      </Heading>
                      <Text
                        color={'#1A1B1A'}
                        fontSize={'16px'}
                        fontWeight={600}
                      >
                        {item.stock > 0 ? item.stock : 'Out of stock'}
                      </Text>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </SimpleGrid>

            {isSearching
              ? totalSearchItems > itemsPerPage && (
                  <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
                    <Pagination
                      totalItems={totalSearchItems}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentSearchPage}
                      onPageChange={page => {
                        setCurrentSearchPage(page);
                        debouncedFetchResults(searchQuery, page);
                      }}
                      isLoading={isSearchLoading}
                    />
                  </Stack>
                )
              : totalItems > itemsPerPage && (
                  <Stack bg={'#fff'} px={4} py={4} borderRadius={12} mt={4}>
                    <Pagination
                      totalItems={totalItems}
                      itemsPerPage={itemsPerPage}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                      isLoading={loading}
                    />
                  </Stack>
                )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
